import * as React from "react"
import useSiteMetadata from "../hooks/useSiteMetadata"
import logoCropped from '../images/Logo-Cropped.svg'
import instagram from '../images/instagram.png'
import linkedin from '../images/linkedin.png'
import xing from '../images/xing.png'
import './index.css'

// markup
const IndexPage = () => {
  const { title } = useSiteMetadata()

  return (
    <main className="layout">
      <title>{title}</title>
      <p className="content">
        <span className="soon">... coming soon ...</span>
        <img className="logo" alt="mrcube42 logo" src={logoCropped} />
        <span className="links">
          <a className="link" target="_blank" href="https://www.linkedin.com/in/davidwuerfel/"
          ><img alt="LinkedIn logo" src={linkedin}
            /></a>
          <a className="link" target="_blank" href="https://www.xing.com/profile/David_Wuerfel3"
          ><img alt="Xing logo" src={xing}
            /></a>
          <a className="link" target="_blank" href="https://www.instagram.com/mrcube42/"
          ><img alt="Instagram logo" src={instagram}
            /></a>
        </span>
      </p>
    </main>
  )
}

export default IndexPage
